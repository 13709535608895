<template>
    <div>
        <v-dialog v-model="show_dialog" width="600" scrollable>
            <v-card>
                <v-card-title class="primary_2 white--text">
                    <div>All Contacts</div>
                    <v-spacer></v-spacer>
                    <div class="text-right">
                        <v-icon large color="white" @click="handleCloseBtn">mdi-close</v-icon>
                    </div>
                </v-card-title>
                <v-card-title class="search_bar">
                    <div class="search_bar">
                        <v-text-field type="text" placeholder="Search.." name="search" outlined v-model="searchQuery"
                            v-on:keyup.enter="getAllContacts()" dense></v-text-field>
                    </div>
                </v-card-title>
                <div class="d-flex justify-end mr-10 pb-2 pt-0 text-caption">
                    <p class="error--text">** Press ENTER to Search</p>
                </div>
                <div class="table_head d-flex mx-9 pa-3 align-center">
                    <div>Name</div>
                    <v-spacer></v-spacer>
                    <div>Number</div>
                </div>
                <v-card-text>
                    <div>
                        <v-data-table :items-per-page="table_length" hide-default-header :headers="headers"
                            :items="contact_list" item-key="id" class="elevation-0 table pa-2"
                            hide-default-footer></v-data-table>
                        <div class="d-flex justify-center" v-if="page_number <= totalPage && page_number >= 0">
                            <div indeterminate id="progressBar" v-intersect="onIntersect"></div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="footer" color="#58ea0430" text-color="#58ea04">Total Number Of Contacts
                    :{{ contactDetails.total_number_of_contacts }}
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "AllContactsDialog",
    data() {
        return {
            headers: [
                {
                    text: 'Contact Name',
                    align: 'start',
                    value: 'name',
                    sortable: false,
                    style: 'background-color: #fcf7ea; font-weight: 800; font-size: initial; color: black;',
                },
                {
                    text: 'Contact Number', value: 'phone', align: 'end', sortable: false,
                    style: 'background-color: #fcf7ea; font-weight: 800; font-size: initial; color: black;',
                },
            ],
            userdata: [],
            search: "",
            show_dialog: false,
            contactDetails: {},
            contact_list: [],
            searchQuery: "",
            totalPage: null,
            page_length: 10,
            page_number: 1,
            table_length: -1,
            shouldApiCall: false
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async childFunction() {
            this.scrollToTop();
            await this.getAllContacts();
            this.show_dialog = true;
        },
        handleCloseBtn() {
            this.show_dialog = false;
            this.searchQuery = "";
            this.page_number = 1;
            this.scrollToTop();
            this.shouldApiCall = false;
        },
        getAllContacts(intersecting = false) {
            const self = this;
            let params = {
                customer_id: self.decrypt(this.$route.params.id),
                search_query: this.searchQuery,
                page_length: this.page_length,
                page_number: this.page_number
            };
            const successHandler = (res) => {
                if (intersecting) {
                    for (const obj of res.data.result.user_data) {
                        this.contact_list.push(obj);
                    }
                } else {
                    this.contact_list = res.data.result.user_data;
                }
                this.contactDetails = res.data.result
                this.totalPage = res.data.result.total_page_count;
            }

            self.request_GET(
                self,
                self.$urls.GET_ALL_CONTACTS,
                params,
                successHandler,
            )
        },
        onIntersect(entries) {
            this.hide = true;
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0 && this.shouldApiCall) {
                    if (this.page_number <= this.totalPage) {
                        this.page_number += 1;
                        this.getAllContacts(true);
                    } else {
                        this.hide = false;
                    }
                }
                this.shouldApiCall = true;
            });
        },
    },
};
</script>
<style>
.subtitle {
    background-color: #fcf7ea;
    font-weight: 800;
    font-size: initial;
}

.theme--light.v-card .v-card__subtitle {
    color: black;
}

.table {
    width: 600px;
    min-height: 500px;
    border-radius: 0 !important;
}

.table_head {
    background-color: #fcf7ea;
    color: black
}

.footer {
    margin-left: -19px;
    font-weight: 600;
    color: black;
    background-color: beige;
    display: flex;
    justify-content: center;
    font-size: small;
}

.v-application .mx-4 {
    margin-right: -5px !important;
    margin-left: 110px !important;
}

.search_bar {
    width: 580px;
    height: 61px;
    margin-left: 5px;
}
</style>
  