<style scoped>
.customer-pofile-card {
  text-align: center;
}

.get-all-contacts-btn {
  position: absolute;
  bottom: 40px;
  right: -25px;
}

.customer-pic {
  width: 90%;
  /* aspect-ratio: 1; */
  border-radius: 100%;
  position: relative;
}

.customer-pic-banner {
  position: absolute;
  /* bottom: 0;
  right: 0; */
  text-align: center;
  /* margin-right: 3px; */
  margin-top: 3px;
}

.customer-old-pic {
  width: 90%;
  aspect-ratio: 1;
  border-radius: 100%;
}

.dummy-pic {
  width: 65%;
  /* aspect-ratio: 1; */
  border-radius: 100%;
}

.switch-text {
  /* text-decoration: underline; */
  /* text-decoration-color: #F18324; */
  color: #f18324;
  font-weight: 900 !important;
  text-decoration-thickness: 3px;
}

.toggle-switch-wrapper {
  background: #f5ab6a56;
  border-radius: 30px;
  max-width: fit-content;
  margin: 0 auto;
  padding: 8px 1px;
  position: relative;
  display: flex;
}

.toggle-switch-text {
  border-radius: 40px;
  padding: 6px;
  font-weight: 800;
  width: 130px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.toggle-switch-bg {
  background: #f18324;
  border-radius: 40px;
  position: absolute;
  /* width: 120px; */
  /* height: 42px; */
  width: 45%;
  height: 38px;
  z-index: 1;
  top: 6px;
  left: 2px;
  transition: transform 400ms ease-in-out;
}

.toggle-switch-bg-left {
  transform: translateX(3%);
}

.toggle-switch-bg-right {
  transform: translateX(115%);
}

.active-switch-text {
  color: #fff;
  transition: color 500ms ease-in-out;
}

.pre-verify-banner {
  color: #e50000;
  font-weight: 700;
  background-color: #e500002d;
  /* background-image:  radial-gradient(rgba(255, 255, 255, 0.434), rgba(255, 0, 0, 0.292)); */
}

.pre-check-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
<template>
  <div>
    <DivLoader
      v-if="loading"
      :loading_type="
        'card-avatar,list-item-two-line,list-item-two-line,list-item-two-line'
      "
    ></DivLoader>
    <div v-else>
      <v-card elevation="5" class="pb-5 pt-4">
        <!-- <div v-if="basic_info.is_in_precheck" class="px-2">
          <div
            class="pre-verify-banner d-flex justify-space-around align-center"
          >
            <p>Pre Verification Requried</p>
            <v-btn depressed small fab text @click="openPreVerificationDialog">
              <v-icon color="error">
                mdi-eye
              </v-icon>
            </v-btn>
          </div>
        </div> -->
        <v-card-text class="customer-pofile-card">
          <div class="mb-5">
            <div class="toggle-switch-wrapper">
              <div :class="toggleSwitchBg"></div>
              <div
                @click="openBasicDetails()"
                class="toggle-switch-text"
                :class="basicSwitchActive"
              >
                Basic Details
              </div>
              <div
                @click="openLoanDetails()"
                class="toggle-switch-text"
                :class="loanSwitchActive"
              >
                Loan Details
              </div>
            </div>
          </div>
          <div>
            <v-progress-circular
              :rotate="270"
              :size="200"
              :width="5"
              :value="basic_info.profile_percentage"
              color="secondary_2"
            >
              <img
                v-if="kyc_profile_image_url && doc_profile_image_url"
                :src="kyc_profile_image_url"
                alt="customer pic"
                class="customer-pic"
              />

              <img
                v-else-if="kyc_profile_image_url && !doc_profile_image_url"
                :src="kyc_profile_image_url"
                alt="customer pic"
                class="customer-pic"
              />
              <img
                v-else-if="doc_profile_image_url && !kyc_profile_image_url"
                :src="doc_profile_image_url"
                alt="customer pic"
                class="customer-old-pic"
              />
              <img
                v-else
                src="../../assets/images/user.svg"
                alt="customer pic"
                class="dummy-pic"
              />
              <img
                v-if="basic_info.is_in_precheck"
                src="../../assets/images/cashpo_precheck.png"
                alt="pre check banner"
                class="customer-pic-banner"
              />

              <v-btn
                dark
                color="primary_2"
                v-if="basic_info.is_in_precheck && show_pre_check_btn"
                @click="openPreVerificationDialog"
                fab
                small
                class="pre-check-btn"
              >
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>

              <v-btn
                dark
                color="primary_2"
                v-if="getPermission('Loan Applied')"
                @click="sendEventToContactsDialog"
                fab
                small
                class="get-all-contacts-btn"
              >
                <v-icon>
                  mdi-account-box-outline
                </v-icon>
              </v-btn>
            </v-progress-circular>
          </div>
          <div class="mt-3 green--text font-weight-bold">
            <span class="text-h6 font-weight-black"
              >{{ basic_info.profile_percentage }}%</span
            >
            Profile Completed
          </div>
          <div class="my-3">
            <p class="text-caption">
              Loan Type
              <v-btn
                text
                fab
                x-small
                class=""
                v-if="getPermission('Change Loan Type')"
                @click="openChangeLoanPermission()"
              >
                <v-icon small class="black--text">mdi-reload</v-icon>
              </v-btn>
            </p>
            <div>
              <div>
                <!-- <v-chip-group column> -->
                <v-chip
                  outlined
                  v-for="(item, index) in basic_info.loan_type"
                  :key="index"
                  class="purple--text font-weight-bold purple lighten-4 ma-1"
                >
                  {{ item.loan_type }}
                </v-chip>
                <!-- </v-chip-group> -->
                <!-- {{item.loan_type}} -->
              </div>
            </div>
          </div>
          <div class="my-3">
            <p class="text-caption">Current Status</p>
            <p>
              <v-chip class="blue--text font-weight-bold blue lighten-4">
                {{ basic_info.profile_status }}
              </v-chip>
            </p>
          </div>
          <div class="my-3">
            <p class="text-caption">Loan Status</p>
            <p v-if="basic_info.loan_status">
              <v-chip class="purple--text font-weight-bold purple lighten-4">
                {{ basic_info.loan_status }}
              </v-chip>
            </p>
            <p v-else>
              -
            </p>
          </div>
          <div class="my-4" v-if="basic_info.loan_counts">
            <p class="text-caption">Loan count</p>
            <div class="">
              <v-chip class="green lighten-4 mr-1">
                <v-icon color="white" class="mr-1 success--text"
                  >mdi-check</v-icon
                >
                <div class="success--text font-weight-black text-body-1">
                  {{ basic_info.loan_counts.completed }}
                </div>
              </v-chip>
              <v-chip class="red lighten-4 ml-1">
                <v-icon color="white" class="mr-1 error--text"
                  >mdi-close</v-icon
                >
                <div class="error--text font-weight-black text-body-1">
                  {{ basic_info.loan_counts.rejected }}
                </div>
              </v-chip>
            </div>
          </div>
          <div>
            <div>
              <p class="text-caption">Lead No.</p>
              <p class="font-weight-black">{{ basic_info.id }}</p>
            </div>
            <div class="font-weight-bold text-h6 black--text">
              {{ basic_info.name }}
            </div>
            <div class="black--text">{{ basic_info.mobile }}</div>
            <div class="black--text mb-3">
              {{ basic_info.email }}
            </div>
            <div v-if="alternate_numbers[0]">
              <div class="text-caption">Alternate Number(s)</div>
              <div
                v-for="item in alternate_numbers"
                :key="item.id"
                class="font-weight-black d-flex align-center justify-center mb-1"
              >
                <span>{{ item.alternate_number }}</span>
                <span>
                  <v-btn
                    outlined
                    x-small
                    fab
                    text
                    class="mx-2"
                    @click="
                      openAlternateNumberDialog(
                        item.id,
                        'edit',
                        item.alternate_number
                      )
                    "
                  >
                    <v-icon small color="secondary_2">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    outlined
                    x-small
                    fab
                    text
                    @click="
                      openAlternateNumberDialog(
                        item.id,
                        'delete',
                        item.alternate_number
                      )
                    "
                  >
                    <v-icon small color="red">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </span>
              </div>
            </div>
            <div class="mb-2 mt-1">
              <div
                class="blue--text font-weight-bold d-flex justify-center align-center"
              >
                <v-icon small color="blue">
                  mdi-plus
                </v-icon>
                <div
                  class="cursor-pointer"
                  @click="openAlternateNumberDialog(null, 'add', '')"
                >
                  Add Alternative Number
                </div>
              </div>
            </div>
            <div class="mt-3">
              <p class="text-caption">Signed-up At</p>
              <div v-if="basic_info.created" class="font-weight-black">
                <p>{{ new Date(basic_info.created).toLocaleTimeString() }},</p>
                <p>
                  {{ new Date(basic_info.created).toDateString() }}
                </p>
              </div>
              <p v-else class="font-weight-black">
                -
              </p>
            </div>
            <div class="mt-3">
              <p class="text-caption">Comment</p>
              <p v-if="old_basic_info.comment" class="font-weight-black">
                {{ old_basic_info.comment }}
              </p>
              <p v-else class="font-weight-black">
                -
              </p>
            </div>
            <div class="mt-3 mb-1">
              <p class="text-caption">Follow-up Date</p>
              <div v-if="basic_info.follow_up_date" class="font-weight-black">
                <p>
                  {{ new Date(basic_info.follow_up_date).toDateString() }}
                </p>
              </div>
              <div v-else class="font-weight-black">
                -
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <AlternateNumberDialog
        v-if="alternate_mobile_dialog_data.flag"
        :dialog="alternate_mobile_dialog_data"
        @success="getAlternateNumbers()"
      />

      <PreVerificationDialog
        v-if="pre_verification_dialog_data.flag"
        :dialog="pre_verification_dialog_data"
        @onSuccess="getBasicInfo()"
      ></PreVerificationDialog>

      <AllContactsDialog ref="childComponentRef"> </AllContactsDialog>
      <ChangeLoanPermissionDialog
        ref="change_loan_permission_dialog"
        @refresh="refreshComponent"
      />
    </div>
  </div>
</template>
<script>
import AllContactsDialog from "../pre-verification/AllContactsDialog.vue";
export default {
  props: ["data"],
  data() {
    return {
      loading: false,
      profile_progress: 20,
      basic_info: [],
      old_basic_info: [],
      kyc_profile_image_url: "",
      doc_profile_image_url: "",
      alternate_mobile_dialog_data: {
        flag: false,
        id: null,
        alternate_number: "",
        action: "",
      },
      alternate_numbers: [],
      pre_verification_dialog_data: {
        flag: false,
        loan_id: null,
        data: [],
      },
      show_pre_check_btn: false,

      contactDetails: {},
    };
  },
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
    AlternateNumberDialog: () =>
      import("@/components/new-customer/dialog/AlternateNumberDialog.vue"),
    PreVerificationDialog: () =>
      import("@/components/pre-verification/PreVerificationDialog.vue"),
    ChangeLoanPermissionDialog: () =>
      import("@/components/common/ChangeLoanPermissionDialog.vue"),
    AllContactsDialog,
  },
  mounted() {
    this.getBasicInfo();
    this.getKycDetails();
    this.getCustomerProfile();
    this.getAlternateNumbers();
  },
  created() {
    this.$root.$refs.getContacts = this;
  },
  methods: {
    refreshComponent() {
      this.getBasicInfo();
      this.getKycDetails();
      this.getCustomerProfile();
      this.getAlternateNumbers();
    },
    openBasicDetails() {
      if (this.toggleSwitch == true) {
        this.toggleSwitch = false;
      }
    },
    openLoanDetails() {
      if (this.toggleSwitch == false) {
        this.toggleSwitch = true;
      }
    },
    toggleActiveSwitch() {
      this.toggleSwitch = !this.toggleSwitch;
    },
    openAlternateNumberDialog(id, action, number) {
      this.alternate_mobile_dialog_data.flag = true;
      this.alternate_mobile_dialog_data.id = id;
      this.alternate_mobile_dialog_data.action = action;
      this.alternate_mobile_dialog_data.alternate_number = number;
    },
    checkPreCheckBtn() {
      let path = this.$route.path.split("/");
      if (path[1] == "pre-verification") {
        this.show_pre_check_btn = true;
      }
    },
    getBasicInfo() {
      const self = this;

      self.loading = true;

      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };
      // console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          // console.log(response);
          self.basic_info = response.data.user_data;
          this.checkPreCheckBtn();
        }
      };
      const finallyHandler = () => {
        self.loading = false;
      };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_BASIC_INFO,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getKycDetails() {
      const self = this;
      let params = {
        customer_id: this.decrypt(this.$route.params.id),
      };

      const successHandler = (response) => {
        if (response.data.success) {
          // console.log("if out");
          if (response.data.kyc_extra_info.selfie_url) {
            // console.log("if in");
            self.kyc_profile_image_url =
              response.data.kyc_extra_info.selfie_url;
          }
        }
      };
      const finallyHandler = () => {};
      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_KYC_DETAILS,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },

    getCustomerProfile() {
      const self = this;
      self.loading = true;
      let params = {
        customer_id: self.decrypt(this.$route.params.id),
      };
      const successHandler = (response) => {
        if (response.data.success) {
          self.doc_profile_image_url = response.data.image_url;
          self.old_basic_info = response.data.profile_data;
        }
      };
      const finallyHandler = () => {
        self.loading = false;
      };
      self.request_GET(
        self,
        self.$urls.GET_BASIC_INFO,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getAlternateNumbers() {
      const self = this;
      let look_up_key = self.decrypt(this.$route.params.id);
      let params = {
        customer_id: self.decrypt(this.$route.params.id),
      };
      const successHandler = (response) => {
        // console.log("GET func",response);
        self.alternate_numbers = response.data.result;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.UPDATE_ALTERNATE_NUMBER,
        look_up_key,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    openPreVerificationDialog(item) {
      // console.log(item);
      if (this.basic_info.agent_assigned) {
        this.pre_verification_dialog_data.flag = true;
        this.pre_verification_dialog_data.data = this.basic_info;
        this.$store.dispatch(
          "pre_verification/setCustomerId",
          this.decrypt(this.$route.params.id)
        );
        this.$store.dispatch(
          "pre_verification/setActiveLoanId",
          this.basic_info.loan_id
        );
      } else {
        this.showSnakeBar("error", "Please assign Agent before Pre-verifying");
      }
    },
    sendEventToContactsDialog() {
      this.$refs.childComponentRef.childFunction();
    },
    openChangeLoanPermission() {
      // let temp = "Business Loan";
      let data = {
        loan_type: [],
      };
      this.basic_info.loan_type.forEach((item) => {
        data.loan_type.push(item.loan_type);
      });
      // temp == "Mutual Fund Loan" ? "" : data.loan_type.push("Mutual Fund Loan");
      this.$refs.change_loan_permission_dialog.openLoanPermissionDialog(data);
    },
  },
  computed: {
    toggleSwitchBg() {
      let a = "toggle-switch-bg";

      if (this.data == false) {
        a = "toggle-switch-bg toggle-switch-bg-left";
        return a;
      } else {
        a = "toggle-switch-bg toggle-switch-bg-right";
        return a;
      }
    },
    basicSwitchActive() {
      let a = "";
      if (this.data == false) a = "active-switch-text";
      return a;
    },
    loanSwitchActive() {
      let a = "";
      if (this.data == true) a = "active-switch-text";
      return a;
    },
    toggleSwitch: {
      get() {
        return this.data;
      },
      set(newValue) {
        this.$emit("toggleDetails");
      },
    },
    activeBasic() {
      let a = "";
      if (this.data == false) a = "switch-text";
      return a;
    },
    activeLoan() {
      let a = "";
      if (this.data == true) a = "switch-text";
      return a;
    },
  },
};
</script>
